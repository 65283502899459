import React, { Component } from "react";
import { Form, Icon, Input, Button } from "antd";
const Password = Input.Password;
type LoginProps = {
  login: (email: string, password: string) => void;
};

class Login extends Component<LoginProps> {
  state = {
    email: "",
    password: ""
  };

  render() {
    return (
      <Form
        className="login"
        layout="inline"
        onSubmit={e => {
          e.preventDefault();
          this.props.login(this.state.email, this.state.password);
        }}
      >
        <Form.Item>
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
            name="email"
            onChange={e => this.setState({ email: e.target.value })}
          />
        </Form.Item>
        <Form.Item>
          <Password
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            name="password"
            placeholder="Hasło"
            onChange={e => this.setState({ password: e.target.value })}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              !(this.state.password.length > 0 && this.state.email.length > 0)
            }
          >
            Zaloguj się
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Login;
