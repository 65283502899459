import React, { Component } from "react";
import { Input, Icon, List, ConfigProvider } from "antd";
import { Domain } from "../App";
const Search = Input.Search;

type DomainListProps = {
  domains: Domain[];
  deleteDomain: (domain: Domain) => void;
};

class MyEmpty extends Component {
  render() {
    return "";
  }
}

export class DomainList extends Component<DomainListProps> {
  state = {
    value: ""
  };
  render() {
    return (
      <div style={{ width: "100%" }}>
        <ConfigProvider renderEmpty={() => <MyEmpty />}>
          <List
            bordered
            loading={false}
            dataSource={this.props.domains}
            renderItem={(domain: Domain) => (
              <List.Item
                actions={[
                  <Icon
                    title="Usuń domenę"
                    onClick={() => this.props.deleteDomain(domain)}
                    type="delete"
                  />
                ]}
              >
                <List.Item.Meta description={domain.name} />
              </List.Item>
            )}
          />
        </ConfigProvider>
      </div>
    );
  }
}
