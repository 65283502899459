import React, { Component } from "react";
import { Input, Icon } from "antd";
import { Domain } from "../App";
const Search = Input.Search;
const uuid = require("uuid/v1");
type DomainInputProps = {
  addDomains: (domains: Domain[]) => void;
};

export class DomainInput extends Component<DomainInputProps> {
  state = {
    value: ""
  };
  render() {
    return (
      <Search
        allowClear={true}
        placeholder="nazwa domeny..."
        enterButton="Dodaj"
        size="default"
        name="domains"
        value={this.state.value}
        onChange={e => {
          const { value } = e.target;
          this.setState({ value });
        }}
        onSearch={(name: string) => {
          if (name.length == 0) {
            return;
          }
          const domains = name
            .split(/\s|,/)
            .map(name => {
              return { name, uuid: uuid() };
            })
            .filter(d => d.name.length > 0);

          this.props.addDomains(domains);
          this.setState({ value: "" });
        }}
      />
    );
  }
}
