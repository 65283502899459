import React, { Component } from "react";
import { authenticate, getDomainData, FetchMode } from "./functions/requests";
import { DomainInput } from "./components/DomainInput";
import { DomainList } from "./components/DomainList";
import { Button, Select, notification } from "antd";
import { utils, writeFile, WorkBook } from "xlsx";
import Login from "./components/Login";
const Option = Select.Option;

async function prepareSpreadsheet(domains: Domain[], fetchMode: FetchMode) {
  const fetchedDomains = await Promise.all(
    domains.map(async d => {
      const fetchedDomain = await getDomainData(d.name, fetchMode);
      if (fetchedDomain.success) {
        return fetchedDomain.domain;
      }
    })
  ).then(domains =>
    domains.filter(d => {
      return typeof d !== "undefined";
    })
  );

  const sheet = utils.json_to_sheet(fetchedDomains);
  let workbook = utils.book_new();
  utils.book_append_sheet(workbook, sheet);
  return workbook;
}

export type Domain = {
  name: string;
  uuid: string;
};

type AppState = {
  domains: Domain[];
  fetchMode: FetchMode;
  loading: boolean;
  authenticated: boolean;
  spreadsheetReady: boolean;
  spreadsheet: WorkBook;
};

type AppProps = {};
class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      domains: [],
      fetchMode: FetchMode.Subdomain,
      loading: false,
      authenticated: false,
      spreadsheetReady: false,
      spreadsheet: {} as WorkBook
    };
  }

  authenticate = async (email: string, password: string) => {
    notification.open({
      key: "init-message",
      message: "Trwa łączenie z serwerem"
    });
    const authenticated = await authenticate(email, password);
    notification.close("init-message");
    if (authenticated) {
      notification.success({ message: "Pomyślnie połączono z API Senuto" });
    } else {
      notification.error({
        message: "Błąd podczas łączenia się z API Senuto",
        description:
          "Możliwy brak połączenia z internetem lub problemy z serwerami Senuto.",
        duration: 10
      });
    }
    this.setState({ authenticated });
  };

  render() {
    return (
      <div>
        {this.state.authenticated && (
          <div className="App">
            <DomainInput
              addDomains={(domains: Domain[]) => {
                this.setState({
                  domains: this.state.domains.concat(domains),
                  spreadsheetReady: false,
                  spreadsheet: {} as WorkBook,
                  loading: false
                });
              }}
            />
            <div className="options">
              <Select
                style={{ marginRight: "0.5rem" }}
                defaultValue={FetchMode.Subdomain}
                onChange={(fetchMode: FetchMode) => {
                  this.setState({
                    fetchMode,
                    spreadsheetReady: false,
                    spreadsheet: {} as WorkBook,
                    loading: false
                  });
                }}
              >
                <Option value={FetchMode.Subdomain}>Wszystkie subdomeny</Option>
                <Option value={FetchMode.Topdomain}>Tylko główna domena</Option>
              </Select>
              <Button
                style={{ marginRight: "0.5rem" }}
                type="danger"
                onClick={() => {
                  this.setState({
                    domains: [],
                    fetchMode: FetchMode.Subdomain,
                    loading: false,
                    spreadsheetReady: false
                  });
                }}
              >
                Zacznij od nowa
              </Button>
              {!this.state.spreadsheetReady && (
                <Button
                  onClick={async () => {
                    this.setState({ loading: true });
                    const spreadsheet = await prepareSpreadsheet(
                      this.state.domains,
                      this.state.fetchMode
                    );
                    if (spreadsheet) {
                      this.setState({ spreadsheetReady: true, spreadsheet });
                    }
                  }}
                  loading={this.state.loading}
                  type="primary"
                >
                  Przygotuj arkusz
                </Button>
              )}

              {this.state.spreadsheetReady && (
                <Button
                  onClick={() => {
                    const fileName = `senuto-${new Date().toLocaleTimeString()}.xlsx`;
                    writeFile(this.state.spreadsheet, fileName);
                  }}
                  type="primary"
                >
                  Pobierz arkusz
                </Button>
              )}
            </div>
            <DomainList
              deleteDomain={(domain: Domain) => {
                const domains = this.state.domains.filter(
                  d => d.uuid !== domain.uuid
                );
                this.setState({ domains });
              }}
              domains={this.state.domains}
            />{" "}
          </div>
        )}

        {!this.state.authenticated && (
          <Login
            login={(email: string, password: string) => {
              this.authenticate(email, password);
            }}
          />
        )}
      </div>
    );
  }
}

export default App;
