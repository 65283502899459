import axios from "axios";
import { Domain } from "domain";
axios.defaults.baseURL = "https://api.senuto.com/";
export default axios;

type Response<T> = {
  data: T;
};

type AuthData = {
  token: string;
};

function isEmpty(obj: any) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

// get token from api
export function authenticate(email: string, password: string) {
  return axios
    .post<Response<AuthData>>("/api/users/token.json", { password, email })
    .then(response => response.data.data)
    .then(response => {
      axios.defaults.headers.common = {
        Authorization: `bearer ${response.token}`
      };
      return true;
    })
    .catch(() => false);
}

type DomainData = {
  statistics: Statistics;
};

type Statistics = {
  visibility: Visibility;
  top3: Keywords;
  top10: Keywords;
  top50: Keywords;
};

type Visibility = {
  recent_value: number;
  older_value: number;
};

type Keywords = { recent_value: number };

export enum FetchMode {
  Subdomain = "subdomain",
  Topdomain = "topLevelDomain"
}

export type FetchedDomainData = {
  visibility: number;
  top3: number;
  top10: number;
  top50: number;
  domain: string;
};

type Result = {
  domain: FetchedDomainData;
  success: boolean;
};

export function getDomainData(
  domain: string,
  fetchMode: FetchMode
): Promise<Result> {
  return axios
    .post<Response<DomainData>>(
      `api/visibility_analysis/reports/dashboard/getDomainStatistics?domain=${domain}&fetch_mode=${fetchMode}`,
      domain
    )
    .then(async response => {
      const { statistics } = response.data.data;
      if (isEmpty(statistics)) {
        throw new Error("Domain not found");
      }
      // const something = await someStuff(domain, fetchMode);
      return {
        success: true,
        domain: {
          domain,
          visibility: statistics.visibility.recent_value,
          top3: statistics.top3.recent_value,
          top10: statistics.top10.recent_value,
          top50: statistics.top50.recent_value
        }
      };
    })
    .catch(() => {
      return {
        domain: {
          domain,
          visibility: 0,
          top3: 0,
          top10: 0,
          top50: 0
        } as FetchedDomainData,
        success: true
      };
    });
}

// kolumna A - domena
//     - kolumna B - Widoczność domeny
//         - kolumna C - Widoczność domeny(bez brandu)
//             - kolumna D - Ilość słów kluczowych w TOP3
//                 - kolumna E - Ilość słów kluczowych w TOP10
//                     - kolumna F - Ilość słów kluczowych w TOP50
